<template>
    <div class="the404">
        <div class="theBox">
            <img src="http://iv.okvu.cn/vugw/img/error404.png" alt="" class="errImg" />
            <div class="theText">
                <a href="">抱歉~目前还没满足你的请求、反馈给威有解决我的需求</a>
            </div>
            <div class="theBtns">
                <div class="tbBtn" @click="goPage">了解其他</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "The404",
    data() {
        return {};
    },
    mounted() {
        setTimeout(() => {
            this.$router.push("/home");
        }, 5000);
    },
    methods: {
        goPage() {
            this.$router.push("/home");
        },
    },
};
</script>

<style lang="less" scoped>
.the404 {
    width: 100%;
    margin: 0 auto;
    height: 700px;
    margin-top: 89px;
    position: relative;
    background-color: #eee;
    overflow: hidden;
    .theBox {
        width: 600px;
        height: 500px;
        text-align: center;
        margin: 0 auto;
        margin-top: 30px;
        .theText {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            font-weight: 900;
            margin-top: 80px;
        }
        .theBtns {
            width: 100%;
            height: 50px;
            margin-top: 40px;
            overflow: hidden;
            .tbBtn {
                width: 110px;
                height: 40px;
                border-radius: 20px;
                background-color: #7781f1;
                margin: 0 auto;
                margin-top: 5px;
                cursor: pointer;
                line-height: 40px;
                color: white;
            }
        }
    }
}
</style>
